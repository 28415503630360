<template>
  <!-- 收入明细 -->
  <div>
    <!-- <div class="tabs">
      <div
        class="tabs-item"
        :class="{ active: active == index }"
        v-for="(item, index) in tabsList"
        :key="index"
        @click="tabChange(index)"
      >
        {{ item.name }}
      </div>
    </div> -->
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      style="min-height: 100vh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :offset="500"
        @load="onLoad"
      >
        <div
          class="list-item slideInRight"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <i class="icon"></i>
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="time">{{ item.add_time }}</div>
          </div>
          <div class="amount">+{{ item.profit }}</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getIncome } from "@/api/recordDetails";
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      loading: false,
      finished: false,
      // tabsList: [
      //   {
      //     name: "全部收入"
      //   },
      //   {
      //     name: "卖卡提成"
      //   },
      //   {
      //     name: "渠道商佣金"
      //   }
      // ],
      dataList: [],
      page: 1
    };
  },
  created() {},
  methods: {
    tabChange(index) {
      this.active = index;
      this.page = 1;
      this.getList();
    },
    onRefresh() {
      this.page = 1;
      this.dataList = [];
      this.getList();
    },
    onLoad() {
      console.log("zengge");
      this.getList();
    },
    getList() {
      let params = {
        page: this.page
      };
      this.isLoading = true;
      getIncome(params).then(res => {
        console.log(res.data, "niubi ");
        if (res.data.code == 200) {
          this.dataList.push(...res.data.data.list);
          console.log(this.dataList, "3212113132");
          ++this.page;
          console.log(this.page, "11111");
          this.isLoading = false;
          this.loading = false;
          if (this.dataList.length <= 0 && res.data.data.list.length <= 0) {
            console.log("ceshi");
            this.loading = true;
          } else {
            console.log("ceshi2");

            this.loading = false;
          }
          if (this.dataList.length >= 0 && res.data.data.list.length <= 0) {
            this.finished = true;
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
body {
  background-color: red;
}
.tabs {
  height: 103px;
  background: #faf6f3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  &-item {
    width: 201px;
    height: 56px;
    background: #ffffff;
    border-radius: 28px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    line-height: 56px;
    text-align: center;
    box-sizing: border-box;
  }
  .active {
    color: #ffffff;
    background: #fbc034;
  }
}
.list-item {
  margin-top: 21px;
  width: 703px;
  height: 149px;
  background: #ffffff;
  border-radius: 14px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 38px 21px;
  // margin-bottom: 22px;
  margin-top: 22px;
  .icon {
    width: 29px;
    height: 28px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 29px;
      font-weight: 500;
      color: #333333;
    }
    .time {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
    }
  }
  .amount {
    margin-left: auto;
    font-size: 35px;
    font-weight: 500;
    color: #333333;
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    margin-bottom: 500px;
  }
  100% {
    margin-bottom: 22px;
  }
}
.slideInRight {
  animation: 0.8s slideInRight;
}
</style>
