import { axios } from "./../plugins/axios";

/**
 * @method 记录明细 - 收入明细
 */
export function getIncome(parameter) {
  return axios.request("", {
    method: "get",
    params: {
      module: "fans",
      action: "profit",
      app: "profit_all",
      ...parameter
    }
  });
}

/**
 * @method 记录明细 - 提现记录
 */
export function cashOut(parameter) {
  return axios.request("", {
    method: "get",
    params: {
      module: "fans",
      action: "profit",
      app: "drawal_list",
      ...parameter
    }
  });
}

/**
 * @method 记录明细 - 提现记录
 */
export function cashMoney(parameter) {
  return axios.request("", {
    method: "get",
    params: {
      module: "fans",
      action: "profit",
      app: "withdrawal",
      ...parameter
    }
  });
}

/**
 * @method 获取收入总结
 */
export function incomeSummary() {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "profit",
      app: "get_profit"
    }
  });
}
